<template>
  <div v-loading="loader">
    <div class="ml-4">
      <back-button/>
    </div>
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1>Add Card & Trophies</h1>
      <trophy-card-form
        @storeTrophyCard="storeTrophyCard"
      />
    </div>
  </div>
</template>

<script>
import TrophyCardForm from "@/views/Pages/SuperAdmin/Cards&Trophies/TrophyCardForm";
import BackButton from "@/components/Router/BackButton"
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";

export default {
  name: "AddTrophyCard",
  components: {TrophyCardForm, BackButton},
  data() {
    return {
      loader: false
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {
    storeTrophyCard(event) {
      let vm = this
      vm.loader = true
      var formData = new FormData()
      _.each(event, (value, key) => {
        formData.append(key, value)
      })
      const config = {headers: {'content-type': 'multipart/form-data'}}
      vm.$store.dispatch('CardsAndTrophiesModule/_storeTrophyCard', {config, formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Trophy Card Created Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          let message = error.response ? vm.generateErrorMessage(error.response.data.errors)
            : 'Something went wrong please try again in few minutes.';
          vm.$notify.error({
            title: 'Trophy Card',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>

</style>
